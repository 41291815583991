import { TextField } from "@mui/material";
import React from "react";

const Input = ({ required, label, type, name, value, onChange }) => {
    return (
        <TextField
            required={required}
            label={label}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            size="small"
            variant="outlined"
            fullWidth
            InputProps={{
                style: {
                    borderRadius: 25,
                },
            }}
        />
    );
};

export default Input;
