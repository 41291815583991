import axios from "axios";

const backendURL = process.env.REACT_APP_BACKEND_URL;

export const register = async (email, password, username) => {
    try {
        const response = await axios.post(`${backendURL}/register`, {
            email: email,
            password: password,
            username: username,
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const verifyRegister = async (email, verificationCode) => {
    try {
        const response = await axios.post(`${backendURL}/verify`, {
            email: email,
            code: verificationCode,
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const auth = async (email, password) => {
    try {
        const response = await axios.post(`${backendURL}/auth`, {
            email: email,
            password: password,
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const cards = async (token) => {
    try {
        const response = await axios.get(`${backendURL}/cards`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return {
            pinnedCards: response.data
                .filter((card) => card.isActive)
                .filter((card) => card.cardType === "BLOCK")
                .sort((a, b) => a.priority - b.priority),
            archivedCards: response.data
                .filter((card) => !card.isActive)
                .filter((card) => card.cardType === "BLOCK")
                .sort((a, b) => a.priority - b.priority),
        };
    } catch (error) {
        throw error;
    }
};

export const favourite = async (token) => {
    try {
        const response = await axios.get(`${backendURL}/profile/favourite`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.filter((card) => card.cardType === "BLOCK");
    } catch (error) {
        throw error;
    }
};

export const create = async (formData, token) => {
    try {
        const response = await axios.post(`${backendURL}/card`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const cardImage = async (token, cardId) => {
    try {
        const response = await axios.get(`${backendURL}/card/${cardId}/image`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: "arraybuffer",
        });

        if (response.status === 200) {
            const blob = new Blob([response.data], {
                type: "image/png",
            });
            return URL.createObjectURL(blob);
        } else {
            throw new Error("Image not found");
        }
    } catch (error) {
        throw error;
    }
};
