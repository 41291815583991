import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import Input from "../../components/Input";
import { auth } from "../../services/api/api";

const Form = () => {
    const { login } = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await auth(email, password);
            const { token, refreshToken } = response.data;
            login(token);
            navigate("/");
        } catch (error) {
            console.error("Authentication error:", error);
            // Обработка ошибок
        }
    };
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
            <form
                style={{ maxWidth: "500px", width: "100%" }}
                onSubmit={handleSubmit}>
                <Stack
                    sx={{
                        gap: 5,
                        textAlign: "center",
                    }}>
                    <Typography variant="title2">Sign In</Typography>
                    <Input
                        required={true}
                        label="Email"
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Input
                        required={true}
                        label="Password"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <Button
                        variant={email && password ? "contained" : "disabled"}
                        type="submit"
                        disabled={!(email && password)}>
                        Submit
                    </Button>
                    <Typography variant="subtitle3">
                        Don't have an account?{" "}
                        <Link href="/signup" underline="hover">
                            Sign Up
                        </Link>
                    </Typography>
                </Stack>
            </form>
        </Box>
    );
};

export default Form;
