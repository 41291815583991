import { Box, Paper, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { cardImage } from "../services/api/api";
import { useAuth } from "../context/AuthContext";

const Card = ({ card, id, index, expanded, dragEnabled }) => {
    const theme = useTheme();
    const { token } = useAuth();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id });

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        zIndex: isDragging ? 300 : 300 - index,
        cursor: dragEnabled ? "grab" : "default",
        borderRadius: 25,
        minWidth: 200,
        maxWidth: 200,
        minHeight: 200,
        maxHeight: 200,
        overflow: "scroll",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: !expanded && index > 0 ? "-100px" : "0px",
    };

    const [image, setImage] = useState(null);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const imageUrl = await cardImage(token, card.id);
                setImage(imageUrl);
            } catch (error) {
                if (error.response && error.response.status !== 404) {
                    console.error("Error fetching image:", error);
                }
            }
        };
        fetchImage();

        return () => {
            setImage(null);
        };
    }, []);

    const draggableAttributes = dragEnabled ? attributes : {};
    const draggableListeners = dragEnabled ? listeners : {};

    return (
        <Paper
            ref={setNodeRef}
            {...draggableAttributes}
            {...draggableListeners}
            style={style}
            variant="elevation"
            elevation={theme.palette.mode === "dark" ? 10 : 5}>
            {!card.text && !image ? (
                <Typography variant="subtitle3">EMPTY CARD</Typography>
            ) : !card.text ? (
                <img
                    src={image}
                    style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "cover",
                    }}
                />
            ) : !image ? (
                <Box sx={{ p: 1, textAlign: "center" }}>
                    <Typography variant="subtitle3">{card.text}</Typography>
                </Box>
            ) : (
                <Box
                    sx={{
                        height: "100%",
                        width: "100%",
                        pt: 5,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                    <img
                        src={image}
                        style={{
                            maxWidth: "67%",
                            maxHeight: "67%",
                            objectFit: "cover",
                            borderRadius: 25,
                        }}
                    />
                    <Box sx={{ p: 1, textAlign: "center" }}>
                        <Typography variant="subtitle3">{card.text}</Typography>
                    </Box>
                </Box>
            )}
        </Paper>
    );
};

export default Card;
