import { Box, Button, Link, Stack, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import { register, verifyRegister } from "../../services/api/api";

const Form = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const otpRefs = useRef([...Array(4)].map(() => React.createRef()));
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const [verify, setVerify] = useState(false);

    const navigate = useNavigate();

    function isOtpComplete() {
        return otp.every((code) => code !== "");
    }

    function handleChange(e, i) {
        if (isNaN(e.target.value)) return false;

        const newValue = e.target.value;
        setOtp((prevOtp) => {
            const updatedOtp = [...prevOtp];
            updatedOtp[i] = newValue;
            return updatedOtp;
        });

        if (newValue && i < 3) {
            otpRefs.current[i + 1].current.focus();
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!verify) {
            try {
                await register(email, password, name);
                setVerify(true);
            } catch (error) {
                console.error("Registration error:", error);
            }
        } else {
            try {
                await verifyRegister(email, otp.join(""));
                setVerify(false);
                navigate("/signin");
            } catch (error) {
                console.error("Code verification error:", error);
            }
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
            <form
                style={{ maxWidth: "500px", width: "100%" }}
                onSubmit={handleSubmit}>
                <Stack
                    sx={{
                        gap: 5,
                        textAlign: "center",
                    }}>
                    {!verify ? (
                        <>
                            <Typography variant="title2">Sign Up</Typography>
                            <Input
                                required={true}
                                label="Email"
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Input
                                required={true}
                                label="Password"
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Input
                                required={false}
                                label="Name"
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />

                            <Button
                                variant={
                                    email && password ? "contained" : "disabled"
                                }
                                type="submit"
                                disabled={!(email && password)}>
                                Submit
                            </Button>
                            <Typography variant="subtitle3">
                                Already have an account?{" "}
                                <Link
                                    sx={{ transition: "all .2s ease" }}
                                    href="/signin"
                                    underline="hover">
                                    Sign In
                                </Link>
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography variant="title2">
                                Verify Email
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: 5,
                                }}>
                                {otp.map((data, i) => {
                                    return (
                                        <TextField
                                            key={i}
                                            type="text"
                                            value={data}
                                            onChange={(e) => handleChange(e, i)}
                                            inputProps={{
                                                maxLength: 1,
                                                style: {
                                                    width: "20px",
                                                    height: "20px",
                                                    textAlign: "center",
                                                    fontSize: "1.5rem",
                                                },
                                            }}
                                            inputRef={otpRefs.current[i]}
                                        />
                                    );
                                })}
                            </Box>
                            <Button
                                variant={
                                    isOtpComplete() ? "contained" : "disabled"
                                }
                                type="submit"
                                disabled={!isOtpComplete()}>
                                Submit
                            </Button>
                        </>
                    )}
                </Stack>
            </form>
        </Box>
    );
};

export default Form;
