import {
    AppBar,
    useTheme,
    Typography,
    Container,
    Button,
    Box,
    IconButton,
} from "@mui/material";
import { useAuth } from "../context/AuthContext.js";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import React from "react";
import Logo from "../assets/Logo.js";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

const Navbar = ({ onClick }) => {
    const theme = useTheme();

    const { token, logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate("/signin");
    };

    return (
        <AppBar
            sx={{
                backgroundColor:
                    theme.palette.mode === "dark"
                        ? "darkglass.main"
                        : "lightglass.main",
                backdropFilter: "blur(15px)",
            }}>
            <Container
                sx={{
                    height: 70,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                <RouterLink
                    to="/"
                    style={{
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                        height: 72,
                    }}>
                    <Logo color={theme.palette.text.primary} size="50px" />
                    <Typography
                        variant="logo"
                        sx={{
                            display: { xs: "none", sm: "block" },
                            color: "text.primary",
                        }}>
                        Flexilink
                    </Typography>
                </RouterLink>
                <Box sx={{ display: "flex", gap: 5 }}>
                    {token ? (
                        <>
                            <Button
                                href="/cards"
                                sx={{
                                    color: "text.primary",
                                    "&:hover": {
                                        color: "primary.main",
                                    },
                                }}>
                                <Typography variant="link">Cards</Typography>
                            </Button>
                            <Button
                                href="/profile"
                                sx={{
                                    color: "text.primary",
                                    "&:hover": {
                                        color: "primary.main",
                                    },
                                }}>
                                <Typography variant="link">Profile</Typography>
                            </Button>
                            <Button variant="outlined" onClick={handleLogout}>
                                <Typography variant="link">Logout</Typography>
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                component={RouterLink}
                                variant="outlined"
                                to="/signin">
                                <Typography variant="link">Sign In</Typography>
                            </Button>
                            <Button
                                component={RouterLink}
                                variant="contained"
                                to="/signup">
                                <Typography variant="link">Sign Up</Typography>
                            </Button>
                        </>
                    )}
                    <IconButton onClick={onClick}>
                        {theme.palette.mode === "dark" ? (
                            <Brightness7Icon
                                sx={{ color: theme.palette.text.primary }}
                            />
                        ) : (
                            <Brightness4Icon
                                sx={{ color: theme.palette.primary.main }}
                            />
                        )}
                    </IconButton>
                </Box>
            </Container>
        </AppBar>
    );
};

export default Navbar;
