import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import Cropper from "react-easy-crop";

const CustomCropper = ({ image, handleCropDone, handleCropCancel }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);

    const handleCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    };

    return (
        <Box
            sx={{
                position: "fixed",
                top: -15,
                left: 0,
                width: "100vw",
                height: "100vh",
                p: 0,
                backgroundColor: "darkglass.main",
                backdropFilter: "blur(5px)",
                zIndex: 1000,
            }}>
            <Cropper
                image={image}
                aspect={1}
                crop={crop}
                zoom={zoom}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={handleCropComplete}
                style={{
                    containerStyle: {
                        height: "100%",
                        width: "100%",
                    },
                    mediaStyle: {},
                    cropAreaStyle: {},
                }}
            />
            <Box
                sx={{
                    zIndex: 3000,
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: "100%",
                    padding: 3,
                    display: "flex",
                    justifyContent: "center",
                    gap: 3,
                }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        handleCropDone(croppedArea);
                    }}
                    sx={{ width: 100 }}>
                    Apply
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={handleCropCancel}
                    sx={{ width: 100 }}>
                    Cancel
                </Button>
            </Box>
        </Box>
    );
};

export default CustomCropper;
