import {
    Box,
    Button,
    Chip,
    Paper,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useState } from "react";
import Input from "../../components/Input";
import Dropzone from "./Dropzone";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import PushPinRoundedIcon from "@mui/icons-material/PushPinRounded";
import CustomCropper from "./Cropper";
import { useAuth } from "../../context/AuthContext";
import { create } from "../../services/api/api";

const Create = ({ handleClose }) => {
    const theme = useTheme();

    const { token } = useAuth();

    const [redirectTo, setRedirectTo] = useState("");
    const [text, setText] = useState("");
    const [image, setImage] = useState("");
    const [croppedImage, setCroppedImage] = useState("");
    const [cancelImage, setCancelImage] = useState("");
    const [isActive, setIsActive] = useState(true);

    const [open, setOpen] = useState(false);

    const handleImageSelected = (image) => {
        setImage(image);
        setOpen(true);
    };

    const handleCropDone = (imageCroppedArea) => {
        const canvasElement = document.createElement("canvas");
        canvasElement.width = imageCroppedArea.width;
        canvasElement.height = imageCroppedArea.height;

        const context = canvasElement.getContext("2d", { alpha: true });

        let imageObject = new Image();
        imageObject.src = image;
        imageObject.onload = function () {
            context.drawImage(
                imageObject,
                imageCroppedArea.x,
                imageCroppedArea.y,
                imageCroppedArea.width,
                imageCroppedArea.height,
                0,
                0,
                imageCroppedArea.width,
                imageCroppedArea.height
            );

            const dataURL = canvasElement.toDataURL("image/png");

            setCroppedImage(dataURL);
            setCancelImage(image);
            setOpen(false);
        };
    };

    const handleCropCancel = () => {
        setOpen(false);
        setImage(cancelImage);
    };

    const handleEdit = () => {
        setOpen(true);
    };

    const handleDelete = () => {
        setImage("");
        setCancelImage("");
        setCroppedImage("");
    };

    const createCard = async () => {
        try {
            const formData = new FormData();
            formData.append("name", "name");
            formData.append("text", text);
            formData.append("redirectTo", redirectTo);
            formData.append("isVisible", true);
            formData.append("isActive", isActive);
            formData.append("priority", 100);
            formData.append("cardType", "BLOCK");
            formData.append("cardSize", "SMALL");

            if (croppedImage) {
                formData.append(
                    "image",
                    dataURLtoBlob(croppedImage),
                    "image.png"
                );
            }

            await create(formData, token);
            alert("Success!");
            handleClose();
        } catch (error) {
            console.error("Error creating a new card:", error);
            alert("Error!");
        }
    };

    const dataURLtoBlob = (dataURL) => {
        const parts = dataURL.split(";base64,");
        const contentType = parts[0].split(":")[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 3,
            }}>
            <Stack spacing={3}>
                <Input
                    label="Redirect to (URL)"
                    type="url"
                    name="redirectTo"
                    value={redirectTo}
                    onChange={(e) => setRedirectTo(e.target.value)}
                />
                <Input
                    label="Text"
                    type="text"
                    name="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <Dropzone handleImageSelected={handleImageSelected} />
                {open ? (
                    <CustomCropper
                        image={image}
                        handleCropDone={handleCropDone}
                        handleCropCancel={handleCropCancel}
                    />
                ) : (
                    <></>
                )}
                <Box sx={{ display: croppedImage ? "flex" : "none", gap: 3 }}>
                    <Button fullWidth variant="outlined" onClick={handleEdit}>
                        Edit
                    </Button>
                    <Button
                        fullWidth
                        variant="outlined"
                        color="error"
                        onClick={handleDelete}>
                        Delete
                    </Button>
                </Box>
            </Stack>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                <Paper
                    sx={{
                        borderRadius: 5,
                        minWidth: 200,
                        maxWidth: 200,
                        minHeight: 200,
                        maxHeight: 200,
                        margin: "auto",
                        overflow: "scroll",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    variant="elevation"
                    elevation={theme.palette.mode === "dark" ? 10 : 5}>
                    {!text && !croppedImage ? (
                        <Typography variant="subtitle3">EMPTY CARD</Typography>
                    ) : !text ? (
                        <img
                            src={croppedImage}
                            style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "cover",
                            }}
                        />
                    ) : !croppedImage ? (
                        <Box sx={{ p: 1, textAlign: "center" }}>
                            <Typography variant="subtitle3">{text}</Typography>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                height: "100%",
                                width: "100%",
                                pt: 5,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}>
                            <img
                                src={croppedImage}
                                style={{
                                    maxWidth: "67%",
                                    maxHeight: "67%",
                                    objectFit: "cover",
                                    borderRadius: 25,
                                }}
                            />
                            <Box sx={{ p: 1, textAlign: "center" }}>
                                <Typography variant="subtitle3">
                                    {text}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Paper>
                <Chip
                    onClick={() => setIsActive(!isActive)}
                    icon={
                        isActive ? (
                            <PushPinRoundedIcon color="black" />
                        ) : (
                            <Inventory2RoundedIcon color="black" />
                        )
                    }
                    label={isActive ? "Pinned" : "Archived"}
                    style={{
                        backgroundColor: isActive ? "palegreen" : "lightgrey",
                        color: "black",
                        textTransform: "uppercase",
                        fontSize: "1rem",
                        padding: 20,
                        borderRadius: 25,
                    }}
                />
                <Button
                    fullWidth
                    disabled={!text && !croppedImage ? true : false}
                    variant="contained"
                    onClick={createCard}>
                    Create
                </Button>
            </Box>
        </Box>
    );
};

export default Create;
