import { Box, Typography } from "@mui/material";
import { useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";

const Dropzone = ({ handleImageSelected }) => {
    const onDrop = useCallback(
        (acceptedFiles) => {
            console.log(acceptedFiles);
            if (acceptedFiles && acceptedFiles.length > 0) {
                const file = acceptedFiles[0];
                const reader = new FileReader();

                reader.readAsDataURL(file);
                reader.onload = () => {
                    handleImageSelected(reader.result);
                };
            }
        },
        [handleImageSelected]
    );

    const handleClick = useCallback(() => {
        inputRef.current.click();
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpeg", ".jpg"],
        },
        maxFiles: 1,
        onDrop,
    });

    const inputRef = useRef(null);

    return (
        <Box
            {...getRootProps({ onClick: handleClick })}
            sx={{
                backgroundColor: "rgba(128, 128, 128, 0.05)",
                border: "dashed 1px rgba(128, 128, 128, 0.5)",
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                minHeight: 100,
                height: "100%",
                borderRadius: 5,
                width: 300,
                p: 3,
                cursor: "pointer",
                "&:hover": {
                    border: "dashed 1px rgba(0, 0, 0, 1)",
                },
            }}>
            <input
                {...getInputProps()}
                ref={inputRef}
                style={{ display: "none" }}
            />
            <Typography
                variant="subtitle3"
                sx={{ m: "auto", textAlign: "center" }}>
                {isDragActive
                    ? "Drop image here..."
                    : "Drag 'n' drop image here, or click to select image (.png .jpg)"}
            </Typography>
        </Box>
    );
};

export default Dropzone;
