import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Box, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home/Home";
import { AuthProvider } from "./context/AuthContext";
import Signin from "./pages/Signin/Signin";
import Signup from "./pages/Signup/Signup";
import Cards from "./pages/Cards/Cards";

const App = () => {
    const [footerHeight, setFooterHeight] = useState(0);
    const [darkMode, setDarkMode] = useState(() => {
        const savedDarkMode = localStorage.getItem("darkMode");
        return savedDarkMode ? JSON.parse(savedDarkMode) : false;
    });

    useEffect(() => {
        localStorage.setItem("darkMode", JSON.stringify(darkMode));
    }, [darkMode]);

    const theme = createTheme({
        palette: {
            mode: darkMode ? "dark" : "light",
            primary: {
                main: "#007fff",
            },
            error: {
                main: "#e32636",
            },
            warning: {
                main: "#f2b400",
            },
            success: {
                main: "#00ad43",
            },
            darkglass: {
                main: "rgba(0, 0, 0, 0.5)",
            },
            lightglass: {
                main: "rgba(255, 255, 255, 0.5)",
            },
        },
        typography: {
            fontFamily: "Outfit, Comfortaa",

            title1: {
                fontWeight: 700,
                fontSize: "5.5rem",
            },
            title2: {
                fontWeight: 700,
                fontSize: "3.5rem",
            },
            title3: {
                fontWeight: 700,
                fontSize: "1.5rem",
            },

            subtitle1: {
                fontWeight: 400,
                fontSize: "1.5rem",
            },
            subtitle2: {
                fontWeight: 400,
                fontSize: "1.25rem",
            },
            subtitle3: {
                fontWeight: 400,
                fontSize: "1rem",
            },

            link: {
                fontWeight: 400,
                fontSize: "1rem",
            },

            logo: {
                fontWeight: 500,
                fontSize: "3rem",
            },
        },
        props: {
            MuiTooltip: {
                arrow: true,
            },
        },
        spacing: 5,
        components: {
            MuiTypography: {
                defaultProps: {
                    variantMapping: {
                        title1: "h1",
                        title2: "h2",
                        title3: "h3",
                        subtitle1: "p",
                        subtitle2: "p",
                        subtitle3: "p",
                        link: "p",
                        logo: "p",
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontSize: 16,
                        borderRadius: 25,
                    },
                },
            },
            MuiBox: {
                styleOverrides: {
                    root: {
                        borderRadius: 25,
                    },
                },
            },
        },
    });

    useEffect(() => {
        const footer = document.getElementById("footer");
        if (footer) {
            const height = footer.offsetHeight;
            setFooterHeight(height);
        }
    }, []);
    return (
        <AuthProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <Navbar onClick={() => setDarkMode(!darkMode)} />
                    <Box
                        sx={{
                            pt: 14,
                            minHeight: `calc(100vh - ${footerHeight}px)`,
                        }}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/signin" element={<Signin />} />
                            <Route path="/signup" element={<Signup />} />
                            <Route path="/cards" element={<Cards />} />
                        </Routes>
                    </Box>
                    <Footer />
                </BrowserRouter>
            </ThemeProvider>
        </AuthProvider>
    );
};

export default App;
