import {
    Box,
    Container,
    Divider,
    IconButton,
    Modal,
    Paper,
    Typography,
    useTheme,
} from "@mui/material";
import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const CustomModal = ({ open, handleClose, title, content }) => {
    const theme = useTheme();
    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{ invisible: true }}
            sx={{
                backgroundColor: "darkglass.main",
                backdropFilter: "blur(15px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
            <Container
                sx={{
                    width: "auto",
                }}>
                <Paper
                    variant="elevation"
                    elevation={5}
                    sx={{
                        border: `1px solid ${theme.palette.default}`,
                        outline: 0,
                        borderRadius: 5,
                        bgcolor: "background.paper",
                        maxHeight: "70vh",
                        minWidth: "300px",
                        overflowY: "auto",
                    }}>
                    <Box
                        sx={{
                            backgroundColor:
                                theme.palette.mode === "dark"
                                    ? "darkglass.main"
                                    : "lightglass.main",
                            backdropFilter: "blur(15px)",
                            position: "sticky",
                            zIndex: 300,
                            top: 0,
                        }}>
                        <Container
                            sx={{
                                height: 70,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "30px",
                            }}>
                            <Typography variant="title3">{title}</Typography>
                            <IconButton
                                size="small"
                                variant="contained"
                                aria-label="toggle-drag"
                                onClick={handleClose}>
                                <CloseRoundedIcon />
                            </IconButton>
                        </Container>
                        <Divider />
                    </Box>
                    <Container sx={{ py: 3 }}>{content}</Container>
                </Paper>
            </Container>
        </Modal>
    );
};

export default CustomModal;
