import { Box, Button, Typography } from "@mui/material";
import React from "react";

const Intro = () => {
    return (
        <Box>
            <Typography variant="title1" sx={{ color: "primary.main" }}>
                3gpaBcTByuTe mama ApTemu9
            </Typography>
            <Typography variant="title1">Flexilink</Typography>
        </Box>
    );
};

export default Intro;
