import React from "react";
import Form from "./Form";
import { Container } from "@mui/material";

const Signin = () => {
    return (
        <Container sx={{ py: 10 }}>
            <Form />
        </Container>
    );
};

export default Signin;
