import React from "react";
import { AppBar, Container, Typography, Box, useTheme } from "@mui/material";

const Footer = () => {
    const theme = useTheme();

    return (
        <AppBar
            id="footer"
            position="static"
            sx={{
                backgroundColor: theme.palette.background.default,
                top: "auto",
                bottom: 0,
            }}>
            <Container>
                <Box
                    sx={{
                        p: 3,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <Typography variant="body2" color="text.secondary">
                        &copy; {new Date().getFullYear()} Flexilink. All rights
                        reserved.
                    </Typography>
                </Box>
            </Container>
        </AppBar>
    );
};

export default Footer;
