import { Container } from "@mui/material";
import React from "react";

import Intro from "./Intro";

const Home = () => {
    return (
        <Container sx={{ py: 10 }}>
            <Intro />
        </Container>
    );
};

export default Home;
