import { Button, ButtonGroup, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Section from "./Section";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import PushPinRoundedIcon from "@mui/icons-material/PushPinRounded";
import { cards, favourite } from "../../services/api/api";
import { useAuth } from "../../context/AuthContext";
import { arrayMove } from "@dnd-kit/sortable";
import CustomModal from "../../components/Modal";
import Create from "./Create";

const Cards = () => {
    const { token } = useAuth();

    const [openCreate, setOpenCreate] = useState(false);
    const [openManage, setOpenManage] = useState(false);

    const [pinnedCards, setPinnedCards] = useState([]);
    const [favoriteCards, setFavoriteCards] = useState([]);
    const [archivedCards, setArchivedCards] = useState([]);

    const getUserCards = async () => {
        try {
            const { pinnedCards, archivedCards } = await cards(token);
            setPinnedCards(pinnedCards);
            setArchivedCards(archivedCards);

            const favoriteCards = await favourite(token);
            setFavoriteCards(favoriteCards);
        } catch (error) {
            console.error("Error getting cards:", error);
        }
    };

    useEffect(() => {
        getUserCards();
    }, [token]);

    const handleDragEnd = (event, setCards) => {
        const { active, over } = event;

        if (active.id === over.id) return;

        setCards((cards) => {
            const originalIndex = cards.findIndex(
                (card) => card.id === active.id
            );
            const newIndex = cards.findIndex((card) => card.id === over.id);

            return arrayMove(cards, originalIndex, newIndex);
        });
    };

    return (
        <Container sx={{ py: 10 }}>
            <ButtonGroup
                sx={{ pb: 6 }}
                disableElevation
                fullWidth
                variant="contained">
                <Button onClick={() => setOpenCreate(true)}>
                    Create a new card
                </Button>
                <Button onClick={() => setOpenManage(true)}>
                    Manage folders
                </Button>
            </ButtonGroup>

            <Section
                folder={{
                    name: "favorite",
                    color: "palegoldenrod",
                    icon: <StarRoundedIcon color="black" />,
                }}
                cards={favoriteCards}
                onDragEnd={(event) => handleDragEnd(event, setFavoriteCards)}
            />

            <Section
                folder={{
                    name: "pinned",
                    color: "palegreen",
                    icon: <PushPinRoundedIcon color="black" />,
                }}
                cards={pinnedCards}
                onDragEnd={(event) => handleDragEnd(event, setPinnedCards)}
            />

            <Section
                folder={{
                    name: "archived",
                    color: "lightgrey",
                    icon: <Inventory2RoundedIcon color="black" />,
                }}
                cards={archivedCards}
                onDragEnd={(event) => handleDragEnd(event, setArchivedCards)}
            />

            <CustomModal
                open={openCreate}
                handleClose={() => setOpenCreate(false)}
                title={"Create a New Card"}
                content={<Create handleClose={() => setOpenCreate(false)} />}
            />

            <CustomModal
                open={openManage}
                handleClose={() => setOpenManage(false)}
                title={"Manage"}
                content={"Hello"}
            />
        </Container>
    );
};

export default Cards;
