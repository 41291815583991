import {
    Chip,
    Paper,
    IconButton,
    Box,
    Divider,
    Typography,
    Grid,
} from "@mui/material";
import React, { useState } from "react";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

import Card from "../../components/Card";
import { SortableContext } from "@dnd-kit/sortable";
import { closestCorners, DndContext } from "@dnd-kit/core";

const Section = ({ folder, cards, onDragEnd }) => {
    const [expanded, setExpanded] = useState(false);
    const [dragEnabled, setDragEnabled] = useState(false);

    return (
        <Paper
            sx={{ my: 3, borderRadius: 5 }}
            variant="elevation"
            elevation={3}>
            <Box
                sx={{
                    p: 3,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                }}>
                <Chip
                    icon={folder.icon}
                    label={folder.name}
                    style={{
                        backgroundColor: folder.color,
                        color: "black",
                        textTransform: "uppercase",
                        fontSize: "1rem",
                    }}
                />
                <Box sx={{ display: "flex", gap: 1 }}>
                    <IconButton
                        size="small"
                        variant="contained"
                        aria-label="toggle-drag"
                        color={dragEnabled ? "primary" : "default"}
                        onClick={() => setDragEnabled(!dragEnabled)}>
                        <DragIndicatorIcon sx={{ fontSize: "1.39rem" }} />
                    </IconButton>
                    <IconButton
                        size="small"
                        variant="contained"
                        aria-label="expand"
                        color={expanded ? "primary" : "default"}
                        onClick={() => setExpanded(!expanded)}>
                        <ExpandCircleDownIcon sx={{ fontSize: "1.39rem" }} />
                    </IconButton>
                </Box>
            </Box>

            <Divider variant="middle" flexItem />

            {cards.length > 0 ? (
                <Grid
                    container={expanded}
                    sx={{
                        display: "flex",
                        p: 3,
                        overflow: "scroll",
                        gap: 3,
                        justifyContent: "left",
                        // justifyContent: expanded ? "?" : "left",
                    }}>
                    <DndContext
                        onDragEnd={(event) => onDragEnd(event)}
                        collisionDetection={closestCorners}>
                        <SortableContext items={cards}>
                            {cards.map((card, index) => (
                                <Card
                                    key={card.id}
                                    card={card}
                                    id={card.id}
                                    index={index}
                                    expanded={expanded}
                                    dragEnabled={dragEnabled}
                                />
                            ))}
                        </SortableContext>
                    </DndContext>
                </Grid>
            ) : (
                <Typography
                    sx={{ py: 5, textAlign: "center" }}
                    variant="title3">
                    No Cards
                </Typography>
            )}
        </Paper>
    );
};

export default Section;
